
import { Tenant } from '@/store/modules/app/types';
import { Namespace, StandardObject } from '@/store/types';
import { TenantHelpers } from '@/utils/tenant-helpers';
import { segmentIdentityTracking } from '@/utils/tracking';
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({})

export default class OopsVue extends Vue {
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('myTenants', { namespace: Namespace.App })
  public myTenants!: Tenant[]
  @State('tenantUserCount', { namespace: Namespace.App })
  public tenantUserCount!: number

  private error: StandardObject = {}
  private image: string = './img/GlitchedLogo_BiColor2_sml.png'

  protected mounted() {
    const hash = window.location.hash
    if (hash) {
      this.error = this.parseQuery(hash)
    }
  }

  private get isUnauthorizedError(): boolean {
    return (this.error && this.error.error === 'unauthorized')
  }

  private get isErrorFromAuth0(): boolean {
    return (this.error && this.error.state !== undefined)
  }

  private parseQuery(queryString: string) {
    const query: StandardObject = {}
    const pairs = ((queryString[0] === '?' || queryString[0] === '#') ?
      queryString.substring(1) : queryString).split('&')
    for (const pair of pairs) {
      const parts = pair.split('=')
      query[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1] || '')
    }
    return query
  }

  private async logout() {
    if (this.myTenant && this.myTenant.ID !== TenantHelpers.InvalidTenantID) {
      await segmentIdentityTracking(this.myTenants, this.myTenant, { TenantUserCount: this.tenantUserCount })
    }
    this.$auth0.logout()
    this.$router.push({ path: '/' })
  }
}
